import VueTypes from 'vue-types'

export default {
  props: {
    src: String,
    background: String,
    size: VueTypes.oneOf(['nano', 'micro', 'small', 'medium', 'big']).def('medium'),
    stereotype: VueTypes.oneOf(['rounded', 'square']).def('rounded'),
    width: [String, Number],
    height: [String, Number],
    fill: {
      type: String,
      default: '#fff'
    },
    color: String
  },
  computed: {
    svgSize () {
      if (this.width && this.height) {
        return {
          height: this.height,
          width: this.width
        }
      }
      let size = '65px'
      if (this.size === 'nano') {
        size = '16px'
      } else if (this.size === 'micro') {
        size = '30px'
      } else if (this.size === 'small') {
        size = '40px'
      } else if (this.size === 'big') {
        size = '90px'
      }
      return {
        height: size,
        width: size
      }
    },
    svgFill () {
      if (this.color) {
        return this.color
      }
      return this.fill
    },
    svgKey () {
      return `${this.svgSize.width}:${this.svgSize.height}:${this.svgFill}:${this.background}:${this.src}`
    }
  }
}
