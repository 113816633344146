import { mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import TxButton from '@/components/lib/TxButton/TxButton.vue'
import TxInlineSvg from '@/components/lib/TxInlineSvg/TxInlineSvg.vue'
import InputComponent from '@/components/lib/InputComponent/InputComponent.vue'
import MessageModal from 'src/modals/lib/MessageModal/MessageModal.vue'
import { oneOf } from 'src/utils'

export default {
  components: {
    TxButton,
    TxInlineSvg,
    InputComponent
  },
  data () {
    return {
      step: 'notfound',
      form: {
        name: '',
        email: '',
        message: ''
      }
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      email: {
        required,
        email
      },
      message: {
        required
      }
    }
  },
  methods: {
    ...mapActions({
      sendMessage: 'public/sendMessage',
      replace: 'modals/replace'
    }),
    oneOf,
    goHome () {
      this.$router.push({ path: '/' })
    },
    contactUs () {
      this.step = 'contact'
    },
    toNotFound () {
      this.step = 'notfound'
      this.$v.$reset()
      this.form.name = ''
      this.form.email = ''
      this.form.message = ''
    },
    handleName (value) {
      this.form.name = value
    },
    handleEmail (value) {
      this.form.email = value
    },
    handleMessage (value) {
      this.form.message = value
    },
    async handleSend () {
      this.$v.$touch()
      if (!this.$v.form.$invalid) {
        const response = await this.sendMessage({
          name: this.form.name,
          type: 'contact-us',
          contact: this.form.email,
          message: this.form.message
        })
        console.log('response', response)
        this.replace({
          factory: () => MessageModal,
          data: {
            type: response.status === 200 ? 'info' : 'error',
            withIcon: true,
            title: response.status === 200
              ? this.i18nSection('thankYou')
              : this.i18nSection('error'),
            message: response.status === 200
              ? this.i18nSection('messageHasBeenSent')
              : this.i18nSection('somethingWentWrong')
          }
        })
        this.toNotFound()
      }
    }
  }
}
