import { mapGetters, mapActions, mapState } from 'vuex'
import { formatNumber } from 'accounting'
import TxInlineSvg from '../TxInlineSvg/TxInlineSvg.vue'
import { oneOf } from 'src/utils'

export default {
  components: {
    TxInlineSvg
  },
  props: {
    value: String,
    title: String,
    isTextarea: Boolean,
    validateInputHandler: Function,
    keypresshandler: Function,
    underlineText: String,
    icon: String
  },
  data () {
    return {}
  },
  async created () {},
  validations: {},
  computed: {
    ...mapState({}),
    ...mapGetters({})
  },
  methods: {
    ...mapActions({}),
    formatNumber,
    oneOf,
    onInputHandler (event) {
      this.validateInputHandler && this.validateInputHandler(event)
      this.$emit('change', event.target.value)
    },
    keyPressHandler (event) {
      this.keypresshandler && this.keypresshandler(event)
    },
    keyPressEnterHandler (event) {
      this.keypressenterhandler && this.keypressenterhandler(event)
    },
    convertCurrencySymbol (symbol) {
      return symbol === 'AUDT' ? 'AUD' : symbol
    }
  }
}
