import { mapActions } from 'vuex'
import VueTypes from 'vue-types'
import { pickClass } from 'src/utils'
import BaseModal from '../BaseModal/BaseModal.vue'

import TxButton from '@/components/lib/TxButton/TxButton.vue'

export default {
  components: {
    TxButton,
    BaseModal
  },
  props: {
    type: VueTypes.oneOf(['error', 'warning', 'info']).def('info'),
    withIcon: Boolean,
    title: String,
    message: String,
    handler: Function,
    isQuestion: Boolean
  },
  data () {
    return {
      isBusy: false
    }
  },
  computed: {
    icon () {
      return this.type === 'info' ? 'shield-ok' : 'warning'
    },
    iconClass () {
      return pickClass({
        'icon-green': this.type === 'info',
        'icon-red': this.type === 'error',
        'icon-orange': this.type === 'warning',
        default: 'icon-green'
      })
    }
  },
  methods: {
    ...mapActions({
      handleClose: 'modals/close'
    }),
    async handleOk () {
      this.isBusy = true
      try {
        this.handler && await this.handler()
      } finally {
        this.isBusy = false
        this.handleClose()
      }
    }
  }
}
